import React from 'react'
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import ImgHeader from "../components/ImgHeader"
import CardListing from '../components/cards/CardListing'
import TitleDiv from "../components/elements/TitleDiv"
import VHCenter from "../components/elements/VHCenter"

import video from "../assets/individuals/video.mp4"
import Title from '../components/elements/Title'
import image from "../assets/individuals/Individuals.jpg"


const Individuals = ({ data }) => {
  const background = data.ImgHeaderQuery.backgroundImg.fluid.src
  const { title } = data.ImgHeaderQuery

  const firstTitle = data.TitleDivQuery.edges[0].node.header
  const secondTitle = data.TitleDivQuery.edges[1].node.header

  const videoSection = data.contentBlock.edges[0].node.body.childContentfulRichText.html
  const videoLink = data.contentBlock.edges[1].node.width
  console.log("videoLink", videoLink)

  return (
    <Layout>
      <SEO title="Individuals" />
      <ImgHeader position="center bottom" image={background} />
      <TitleDiv color="#feb600" style={{ marginTop: "0" }}>
        <VHCenter><div className="text-uppercase"><Title><div dangerouslySetInnerHTML={{ __html: firstTitle }}></div></Title></div></VHCenter>
      </TitleDiv>
      <div className="media-with-text container">
        <div className="media-with-text--media container">
          <iframe width="100%" height="380" src={videoLink} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
        <div class="media-with-text--text" dangerouslySetInnerHTML={{ __html: videoSection }}>
        </div>
        <div class="clear-both"></div>
      </div>
      <TitleDiv color="#85b8cd">
        <VHCenter><div class="text-uppercase"><Title><div dangerouslySetInnerHTML={{ __html: secondTitle }}></div></Title></div></VHCenter>
      </TitleDiv>
      <div className="container" style={{ marginTop: "5%", marginBottom: "5%" }}>
        <CardListing path="/individuals" />
      </div>
    </Layout >
  )
}

export default Individuals

export const query = graphql`
    query IndiviualsPage {
      ImgHeaderQuery: contentfulImgHeader(path: { eq: "/individuals" }) {
        title
        path
        backgroundImg {
          fluid(quality: 100) {
            src
          }
        }
      }
          TitleDivQuery: allContentfulTitleDiv(
            filter: { path: { eq: "/individuals" }}, 
            sort: { fields: orderId }
            )
          {
            edges {
              node {
                orderId
                header 
              }
            }
          }
          contentBlock: allContentfulBlocks(
            filter: { path: { eq: "/individuals" } }
            sort: { fields: orderId }
          ) {
            edges {
              node {
                width
                body {
                  childContentfulRichText {
                    html
                  }
                }
                image{
                    fluid{
                        src
                    }
                }
              }
            }
          }
}
`
